import React, { useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-recaptcha";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import './careers.scss';

const handleFocus = e => e.target.parentNode.classList.add('focused');
const handleBlur = e => e.target.parentNode.classList.remove('focused');

const Careers = () => {
    const data = useStaticQuery(graphql`
        {
            careersBg: file(relativePath: {eq: "careers_bg.jpg"}) {
                id
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
              }
        }
    `);

    const formRef = useRef();

    const [formStatus, setFormStatus] = useState('test');
    const [recaptchaState, setRecaptchaState] = useState(null);
    const [filenameState, setFilenameState] = useState('');
    const { register, handleSubmit, formState, reset } = useForm({
        mode: 'all',
        defaultValues: {
            name: "",
            surname: "",
            phone: "",
            email: "",
            file: null
        }
    });

    const handleOnFileInputChange = e => {
        if(e.target.files.length > 0)
            setFilenameState(e.target.files[0].name);
    }

    const cutFileName = name => name.length > 20 ? name.slice(0, 5) + '(...).' + /(?:\.([^.]+))?$/.exec(name)[1] : name;

    const { errors, dirtyFields, isValid } = formState;

    const onSubmit = data => {

        const formData = new FormData(formRef.current);

        formData.append("template", "applyTemplate");
        formData.append("subject", `Formularz aplikacyjny | ${data.name} ${data.surname}`);

        fetch("https://konto-jarocin.herokuapp.com/sendmail", {
            method: "POST",
            body: formData,
          })
            .then(function (response) {
                changeFormStatus('success')
                reset();
            })
            .catch(function (error) {
                changeFormStatus('error')
            });
    }

    const verifyCallback = value => setRecaptchaState(value);
    const expiredCallback = () => setRecaptchaState(null);
    const onloadCallback = () => console.log('reCAPTCHA loaded successfully!');

    const changeFormStatus = (status) => {
        setFormStatus(status);
        setTimeout(() => setFormStatus(null), 5000);
    }

    return (
        <section id="kariera" className="careers">
            <BackgroundImage Tag="header" className="careers-header" fluid={data.careersBg.childImageSharp.fluid}>
                <div className="careers-header-content">
                    <h1 className="border">
                        Kariera
                    </h1>
                    <p className="subtitle">
                        Aplikuj i dołącz do naszego zespołu
                    </p>
                </div>
            </BackgroundImage>
            <div className="content">
                <div className="apply-form">
                    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                        <div className="form-control fileinput">
                            <label htmlFor="file">
                                <div className="content">
                                    <Icon icon={faFilePdf} size="2x"/>
                                    Załącz CV w formacie PDF, aby aplikować
                                </div>
                                <div className="line-button" style={{textAlign: 'center'}}>{filenameState ? `Załączono: ${cutFileName(filenameState)}` : 'Wybierz plik'}</div>
                            </label>
                            <input type="file" accept="application/pdf" name="file" id="file" onChange={handleOnFileInputChange} ref={register({required: true})}/>
                        </div>
                        <div className={`form-control input ${dirtyFields.name ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                            <label htmlFor="name">{`Imię ${errors.name ? ` - To pole jest wymagane!` : ''}`}</label>
                            <input type="text" name="name" id="name" ref={register({required: true})}/>
                        </div>
                        <div className={`form-control input ${dirtyFields.surname ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                            <label htmlFor="name">{`Nazwisko ${errors.surname ? ` - To pole jest wymagane!` : ''}`}</label>
                            <input type="text" name="surname" id="surname" ref={register({required: true})}/>
                        </div>
                        <div className={`form-control input ${dirtyFields.email ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                            <label htmlFor="email">{`Email ${errors.email ? errors.email.type === 'pattern' ? ` - Adres email jest nieprawidłowy!` : ` - To pole jest wymagane!` : ''}`}</label>
                            <input type="email" name="email" id="email" ref={register({required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}/>
                        </div>
                        <div className={`form-control input ${dirtyFields.phone ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                            <label htmlFor="phone">{`Numer telefonu ${errors.phone && errors.phone.type === 'pattern' ? ` - Numer telefonu jest nieprawidłowy! Dozwolone znaki: 0-9` : ''}`}</label>
                            <input type="tel" name="phone" id="phone" ref={register({required: false, pattern: /^\d+$/})}/>
                        </div>
                        <div className="form-checkbox">
                            <input type="checkbox" name="form-privacy" id="form-privacy" ref={register({required: true})}/>
                            <label htmlFor="form-privacy"><span style={{color: "red", fontSize: "2em"}}>*</span> Oświadczam, że wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu przez Biuro Usług Finansowo-Księgowych "KONTO" Artur Tomczak z siedzibą w Jarocinie (63-200), ul. Jarmarczna 16 oraz Partnerów współpracujących z Biuro Usług Finansowo-Księgowych "KONTO" Artur Tomczak</label>
                        </div>
                        <div className="recaptcha-container">
                            <ReCAPTCHA
                                sitekey="6LeKYD4aAAAAADoZQGFxSt9yeC9QYXR0d89wd3ZE"
                                render="explicit"
                                hl="pl"
                                onloadCallback={onloadCallback}
                                verifyCallback={verifyCallback}
                                expiredCallback={expiredCallback}
                            />
                            <button type="submit" className="line-button" style={{fontWeight: '400'}} disabled={!(isValid && recaptchaState !== null)}>Aplikuj</button>
                        </div>
                        {formStatus === 'success' && <div style={{color: 'green'}}>{`Wiadomość została pomyślnie wysłana.`}</div>}
                        {formStatus === 'error' && <div style={{color: 'red'}}>{`Wystąpił problem podczas wysyłania wiadomości.`}</div>}
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Careers;