import React from 'react';

import Layout from '../components/layout';
import Careers from '../components/careers/careers';

export default function CareersPage() {
    return (
        <Layout title="Kariera">
            <Careers />
        </Layout>
    )
}